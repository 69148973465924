<template>
  <section id="interactivities">
    <b-row class="mb-5">
      <b-col
        md="9"
        class="pb-3 pb-md-0"
      >
        <h1>{{ $t('interactivities') }}</h1>
        <h5 class="text-primary">
          {{ $t('interactivities.list') }}
        </h5>
      </b-col>
      <b-col
        md="3"
        class="text-right"
        align-self="end"
      />
    </b-row>
    <b-row>
      <b-col
        v-for="r in response"
        :key="r.node.id"
        class="mt-5"
        md="6"
        lg="3"
        xl="3"
        sm="12"
      >
        <b-card @dblclick="routeToView(r.node.id)">
          <h4>{{ r.node.name }}</h4>
          <br>

          <b-dropdown
            variant="link"
            toggle-class="text-decoration-none"
            no-caret
          >
            <template #button-content>
              <feather-icon
                icon="MoreHorizontalIcon"
                size="20"
              />
            </template>

            <b-dropdown-item @click="routeToEditView(r.node.id)">
              <feather-icon
                icon="Edit2Icon"
                size="18"
              />
              {{ $t('dataGeneric.edit') }}
            </b-dropdown-item>
            <b-dropdown-item @click="deleteInteractivities(r.node.id)">
              <feather-icon
                icon="DeleteIcon"
                size="18"
              />
              {{ $t('dataGeneric.delete') }}
            </b-dropdown-item>
          </b-dropdown>
        </b-card>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BDropdown,
  BDropdownItem,
} from 'bootstrap-vue'
import { messageError, showToast } from '@/store/functions'

import axios from '@axios'
import { getUserData } from '@/auth/utils'

export default {
  components: {
    BDropdown,
    BDropdownItem,
    BRow,
    BCol,
    BCard,
  },
  props: {
    msg: Boolean,
  },
  data() {
    return {
      headers: {},
      response: null,
      vacio: false,
    }
  },
  mounted() {
    let defaultLang = 'es'
    try {
      defaultLang = getUserData.profile.client.defaultLanguage
      // eslint-disable-next-line no-empty
    } catch (error) {}

    this.headers = {
      'Accept-Language': defaultLang,
      'Content-Language': defaultLang,
    }
    this.getData()
  },
  methods: {
    getData() {
      const { headers } = this

      const userData = getUserData()
      axios
        .post('', {
          query: `
          query{
            allInteractivities(client:"${userData.profile.client.id}"){
              totalCount
              edges {
                node {
                  id
                  name
                  description
                  image
                  
                }
              }
            }
          }
        `,
        }, { headers })
        .then(res => {
          messageError(res, this)
          this.response = res.data.data.allInteractivities.edges
        })
    },
    routeToView(subsid) {
      this.$router.push({
        name: 'interactivities-view',
        params: { id: subsid },
      })
    },
    routeToEditView(subsid) {
      this.$router.push({
        name: 'interactivities-edit',
        params: { id: subsid },
      })
    },
    deleteInteractivities(id) {
      this.$swal({
        title: this.$t('code.title'),
        text: this.$t('code.text'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$t('code.confirm'),
        cancelButtonText: this.$t('dataGeneric.cancel'),
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          axios
            .post('', {
              query: `
          mutation{
            deleteInteractivities(id:"${id}"){
              found
              deletedId
            }
          }
        `,
            })
            .then(res => {
              messageError(res, this)
              showToast(this.$t('success'), 1, this)

              this.getData()
            })
            .catch(() => {
              showToast(this.$t('error'), 2, this)
            })
        }
      })
    },
    createInteractivities() {
      this.$router.push({
        name: 'interactivities-create',
      })
    },
  },
}
</script>

<style lang="scss" scoped>
#interactivities .row {
  padding: 1.3rem;
}

#interactivities .row .col {
  font-size: 1.285rem !important;
}

#interactivities .card-title {
  font-weight: 500;
}
</style>
